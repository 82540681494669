import React, { useCallback, useState } from 'react'
import { navigate } from 'gatsby'
import { Button, Icon, Modal } from 'semantic-ui-react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/effect-coverflow/effect-coverflow.scss'
// import 'swiper/components/pagination/pagination.scss'

import SEO from '../../components/SEO'
import LandingPageLeadForm from '../../components/LandingPageLeadForm'
import Vimeo from '../../components/Vimeo'

import Logo from '../../images/logos/contego-logo.png'

import { callHandler } from '../../ultilities'

const ownerReviews = [
  {
    id: 490896162,
    frameRatio: 9 / 16
  },
  { id: 490899944, frameRatio: 9 / 16 },
  { id: 490896933, frameRatio: 9 / 16 },
  { id: 490983754, frameRatio: 9 / 16 }
]

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay, EffectCoverflow])

const SmartInvestment = () => {
  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  return (
    <>
      <div className="container">
        <SEO
          title="ĐẦU TƯ THÔNG MINH, ĐẦU TƯ GHẾ CONTÉGO?"
          description="Vì sao đầu tư ghế pedicure spa massage chair của CONTEGO là tốt nhất."
          meta={[
            {
              name: 'keywords',
              content: 'GIÁ TRỊ ghế Contégo, lợi ích ghế Contego, hữu ích, lợi ích, ghế Contego'
            }
          ]}
        />

        <div className="row">
          <div className="col py-4 ld-header">
            <img src={Logo} width="120px" height="auto" alt="contego company logo" />

            <h4 className="my-auto">LANDING PAGE</h4>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <Vimeo id={490899622} title="intro" autoPlay frameRatio={16 / 9} threshold={[0, 1]} />
          </div>
        </div>

        <div className="row">
          <div className="col py-4">
            <h2>V-Sport</h2>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <Vimeo id={490898011} title="V-Sport Promo" frameRatio={9 / 16} threshold={[0, 1]} />
          </div>
        </div>

        <div className="row">
          <div className="col py-4">
            <h2>Magna</h2>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <Vimeo id={490901868} title="Magna Promo" frameRatio={9 / 16} threshold={[0, 1]} />
          </div>
        </div>

        <div className="row">
          <div className="col py-4">
            <h2>VIP</h2>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <Vimeo id={490901485} title="VIP Promo" frameRatio={9 / 16} threshold={[0, 1]} />
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col">
            <h2>CHIA SẺ CỦA MỘT SỐ THỢ, KHÁCH HÀNG VÀ CHỦ TIỆM</h2>
          </div>
        </div>

        <br />

        <Swiper
          effect="coverflow"
          slidesPerView={1.2}
          coverflowEffect={{ slideShadows: false }}
          pagination={{ type: 'bullets', clickable: true }}
          mousewheel={{ forceToAxis: true }}
        >
          {ownerReviews.map(item => {
            return (
              <SwiperSlide key={item.title}>
                <Vimeo
                  title={item.id}
                  id={item.id}
                  frameRatio={item.frameRatio}
                  threshold={[0.5, 1]}
                />
              </SwiperSlide>
            )
          })}
          <br /> <br />
        </Swiper>

        <h2>
          Để được tư vấn thêm{' '}
          <Button
            className="call-now-btn rounded-pill text-white shadow-sm"
            data-phone-number="7148958865"
            onClick={handleCallNow}
          >
            GỌI NGAY <Icon name="phone" className="m-0" />
          </Button>{' '}
          Hoặc để lại thông tin liên lạc bên dưới, chúng tôi sẽ gọi.
        </h2>

        <LandingPageLeadForm onSuccess={handleSuccess} />
        <br />
        <br />
        <br />
        <br />
        <MobileNativeNav />
      </div>
    </>
  )
}

const MobileNativeNav = ({ onClose }) => {
  const [isShown, setIsShown] = useState(false)

  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  const toggleViewForm = useCallback(() => {
    setIsShown(isShown => !isShown)
  }, [])

  const handleCloseLeadForm = useCallback(() => {
    setIsShown(false)
    onClose && onClose()
  }, [onClose])

  return (
    <div className="mobile-native-nav">
      <div className="px-2 py-2 d-flex justify-content-between align-items-center shadow">
        <Button onClick={toggleViewForm} className="my-2 rounded-pill shadow-sm" primary>
          LIÊN LẠC TÔI
        </Button>

        <Button
          className="call-now-btn rounded-pill text-white shadow-sm"
          data-phone-number="7148958865"
          onClick={handleCallNow}
        >
          GỌI NGAY <Icon name="phone" className="m-0" />
        </Button>
      </div>

      <Modal open={isShown}>
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <img src={Logo} alt="contego logo" className="d-block" width="180px" height="auto" />
            <Button icon color="red" onClick={handleCloseLeadForm}>
              <Icon name="close" className="text-white" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <LandingPageLeadForm onSuccess={handleSuccess} />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default SmartInvestment
